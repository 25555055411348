<template>
  <div class="drawing-new" v-loading.fullscreen.lock="loading">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <el-form class="form" ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item :label="$t('gpt.desc')">
        <el-input v-model="form.desc" size="small" style="width: 60%"></el-input>
      </el-form-item>
      <el-form-item :label="$t('gpt.mask')" prop="maskId" required>
        <div class="mask-tab">
          <el-tabs v-model="mode" type="card">
            <el-tab-pane :label="$t('gpt.upload')" name="upload">
              <div class="upload-container">
                <el-upload :action="aiApiRoot + '/v1/images'" accept=".jpg, .jpeg, .png"
                  :headers="{ Authorization: this.token }" list-type="picture-card" :file-list="fileList"
                  :data="{ group: 'gpt' }" :on-change="handleChange" :on-remove="handleRemove" :multiple="true">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('gpt.selfDrawing')" name="drawing">
              <sketch-canvas ref="sketchCanvas" />
            </el-tab-pane>
            <el-tab-pane :label="$t('gpt.select')" name="select">
              <el-card key="target" class="target-container" shadow="none" v-loading="maskLoading">
                <el-row :gutter="5">
                  <el-col class="target-block" :span="4" v-for="item in masks" :key="item.id">
                    <el-row>
                      <el-radio v-model="form.maskId" :label="item.id">{{ item.name }}</el-radio>
                    </el-row>
                    <el-row>
                      <el-image class="target-img" :src="item.url | imageUrl(false)"
                        :preview-src-list="[imageUrl(item.url, false)]" fit="contain" />
                    </el-row>
                  </el-col>
                </el-row>
                <el-row>
                  <pagination v-show="maskTotal > 0" :total="maskTotal" :offset.sync="maskListQuery.offset"
                    :limit.sync="maskListQuery.limit" layout="prev, pager, next " @pagination="fetchMaskData" />
                </el-row>
              </el-card>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-form-item>
      <div class="form-block">
        <el-col class="form-block-item" :span="17">
          <el-form-item :label="$t('gpt.option')">
            <el-select v-model="form.loraInfos[0].id" filterable style="width: 60%">
              <el-option v-for="item in loraList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <!-- <el-input-number class="input-number" v-model="form.loraInfos[0].multiplier" :precision="1" :step="0.1"
              :min="0" :max="1"></el-input-number> -->
            <div class="notice">
              <i18n path="gpt.modelNotice" tag="div">
                <span class="brand">A,B,C,D</span>
              </i18n>
            </div>
          </el-form-item>
        </el-col>
        <el-col class="form-block-item" :span="6">
          <img class="concept-axis" :src="ossRoot + '/' + env + '/gpt/assets/concept_axis_en.png'" alt="concept" />
        </el-col>
      </div>
      <el-form-item class="page-tab" :label="$t('gpt.color')">
        <el-tabs v-model="form.colorType" type="card">
          <el-tab-pane class="tab-content" :label="$t('gpt.selfPrompt')" name="custom">
            <el-input style="width: 80%;" type="textarea" :rows="2" placeholder="" v-model="form.customColor">
            </el-input>
          </el-tab-pane>
          <el-tab-pane class="tab-content" :label="$t('gpt.color')" name="select">
            <el-select v-model="form.color" filterable>
              <el-option v-for="item in colorList" :key="item.key" :label="item.key" :value="item.key"></el-option>
            </el-select>
          </el-tab-pane>
        </el-tabs>
      </el-form-item>
      <el-form-item class="page-tab" :label="$t('gpt.pattern')">
        <el-tabs v-model="form.patternType" type="card">
          <el-tab-pane class="tab-content" :label="$t('gpt.selfPrompt')" name="custom">
            <el-input style="width: 80%;" type="textarea" :rows="2" placeholder="" v-model="form.customPattern">
            </el-input>
          </el-tab-pane>
          <el-tab-pane class="tab-content" :label="$t('gpt.pattern')" name="select">
            <el-select v-model="form.pattern" filterable>
              <el-option v-for="item in patternList" :key="item.key" :label="item.key" :value="item.key"></el-option>
            </el-select>
          </el-tab-pane>
        </el-tabs>
      </el-form-item>
      <el-form-item class="page-tab" :label="$t('gpt.fabric')">
        <el-tabs v-model="form.fabricType" type="card">
          <el-tab-pane class="tab-content" :label="$t('gpt.selfPrompt')" name="custom">
            <el-input style="width: 80%;" type="textarea" :rows="2" placeholder="" v-model="form.customFabric">
            </el-input>
          </el-tab-pane>
          <el-tab-pane class="tab-content" :label="$t('gpt.select')" name="select">
            <el-select v-model="form.fabric" filterable>
              <el-option v-for="item in fabricList" :key="item.key" :label="item.key" :value="item.key"></el-option>
            </el-select>
          </el-tab-pane>
        </el-tabs>
      </el-form-item>
      <el-form-item :label="$t('gpt.imageCount')" required>
        <el-input-number v-model="form.imageCount" :min="1" :max="10"></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-s-promotion" @click="onSubmit">{{ $t("common.submit") }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { createTask, fetchTargets, fetchOptions } from "@/api/gpt";
import { uploadImage } from "@/api/image";
import { getToken } from "@/utils/auth";
import { aiApiRoot } from "@/utils/server";
import Pagination from "@/components/Pagination";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import SketchCanvas from "@/components/SketchCanvas";
import { imageUrl } from "@/filters";
import { ossRoot } from "@/utils/server";

export default {
  components: {
    Pagination,
    AiBreadcrumb,
    SketchCanvas,
  },
  data() {
    return {
      token: "Bearer " + getToken(),
      aiApiRoot,
      ossRoot: ossRoot,
      masks: [],
      maskListQuery: {
        offset: 0,
        limit: 12,
        group: "drawing-mask"
      },
      maskTotal: 0,
      maskLoading: false,
      loraList: [],
      colorList: [],
      patternList: [],
      fabricList: [],
      form: {
        desc: "",
        loraInfos: [{
          id: null,
          multiplier: 1
        }],
        maskId: 0,
        colorType: "custom",
        customColor: "",
        color: "",
        patternType: "custom",
        customPattern: "",
        pattern: "",
        fabricType: "custom",
        customFabric: "",
        fabric: "",
        imageCount: 5,
      },
      loading: false,
      rules: {
        maskId: [
          {
            validator: (rule, value, callback) => {
              if (value <= 0) {
                callback(new Error('Please select mask'));
              } else {
                callback();
              }
            }, message: "Please select mask", trigger: 'change'
          }
        ],
      },
      fileList: [],
      mode: "upload"
    };
  },
  methods: {
    onSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          let params = { ...this.form, category: "drawing" }
          if (this.mode === 'upload') {
            if (this.fileList.length === 0) {
              this.$message({
                message: "Upload image please",
                type: "warning",
              });
              return;
            }
            if (this.fileList[0].status !== "success") {
              this.$message({
                message: "Image upload not finish, can't submit",
                type: "warning",
              });
            }
            let fileResp = this.fileList[0].response;
            if (!fileResp.result || fileResp.result.length === 0 || !fileResp.result[0].id) {
              this.$message({
                message: "Invalid image id for upload, can't submit",
                type: "warning",
              });
            }
            let imageId = fileResp.result[0].id;
            params.sourceIds = [imageId];
          } else if (this.mode === 'drawing') {
            this.saveDrawing().then(fileResp => {
              if (!fileResp.result || fileResp.result.length === 0 || !fileResp.result[0].id) {
                this.$message({
                  message: "Invalid image id for drawing, can't submit",
                  type: "warning",
                });
              }
              let imageId = fileResp.result[0].id;
              params.sourceIds = [imageId];

              createTask(params).then((response) => {
                this.loading = false;
                if (response.success) {
                  this.$notify({
                    title: this.$t("common.saveSucc"),
                    message: this.$t("common.indirecting"),
                    type: "success",
                  });
                  let suffix = response.result && response.result.id ? "/" + response.result.id : ""
                  this.$router.push("/services/gpt-drawing/drawing" + suffix).catch(() => { });
                }
              }).catch(() => {
                this.loading = false;
              });
            }).catch(() => {
              this.loading = false;
            })
            return;
          }
          createTask(params).then((response) => {
            this.loading = false;
            if (response.success) {
              this.$notify({
                title: this.$t("common.saveSucc"),
                message: this.$t("common.indirecting"),
                type: "success",
              });
              let suffix = response.result && response.result.id ? "/" + response.result.id : ""
              this.$router.push("/services/gpt-drawing/drawing" + suffix).catch(() => { });
            }
          })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    async saveDrawing() {
      const dataURL = this.$refs.sketchCanvas.saveImage();
      const response = await fetch(dataURL);
      const blob = await response.blob(); // 将数据 URL 转换为 Blob
      const formData = new FormData();
      formData.append('image', blob, 'drawing.png'); // 将 Blob 添加到 FormData
      return uploadImage(formData);
    },
    fetchMaskData() {
      this.maskLoading = true;
      fetchTargets(this.maskListQuery).then(response => {
        if (response.success) {
          this.masks = response.result && response.result.items ? response.result.items : [];
          this.maskTotal = response.result.totalCount;
          if (this.masks.length > 0) {
            this.form.maskId = this.masks[0].id;
          }
        }
      }).finally(() => {
        this.maskLoading = false;
      })
    },
    fetchLoraList() {
      this.loraList = [];
      fetchTargets({ limit: -1, group: "concept-lora" }).then(response => {
        if (response.success) {
          this.loraList = response.result && response.result.items ? response.result.items : [];
          if (this.loraList.length > 0) {
            this.form.loraInfos[0].id = this.loraList[0].id;
          }
        }
      })
    },
    getOptions(type) {
      let data = { "type": type }
      fetchOptions(data).then(response => {
        if (response.success) {
          if (type === "drawingColor") {
            this.colorList = response.result && response.result.items
              ? response.result.items : [];
            this.form.color = this.colorList.length > 0 ? this.colorList[0].key : "";
          } else if (type === "drawingPattern") {
            this.patternList = response.result && response.result.items
              ? response.result.items : [];
            this.form.pattern = this.patternList.length > 0 ? this.patternList[0].key : "";
          } else if (type === "drawingFabric") {
            this.fabricList = response.result && response.result.items
              ? response.result.items : [];
            this.form.fabric = this.fabricList.length > 0 ? this.fabricList[0].key : "";
          }
        }
      })
    },
    imageUrl(url, isExternal) {
      return imageUrl(url, isExternal);
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
  },
  mounted() {
    this.fetchMaskData();
    this.fetchLoraList();
    this.getOptions("drawingColor");
    this.getOptions("drawingPattern");
    this.getOptions("drawingFabric");
  },
  computed: {
    env() {
      let env = process.env.VUE_APP_ENV;
      if (!env) {
        return "staging";
      }
      if (env === "production.kr") { env = "production" }
      return env
    }
  }
};
</script>

<style lang="scss" scoped>
.drawing-new {
  .page-tab {
    background-color: white;

    /deep/ .el-tabs__header {
      padding-top: 10px;
      padding-left: 10px;
      margin-bottom: 0;
    }

    /deep/ .el-tabs__item {
      height: 34px;
      line-height: 34px;
    }

    .tab-content {
      margin: 10px;
    }
  }

  .form {
    margin-top: 25px;

    .form-block {
      overflow: hidden;

      .form-block-item {
        float: left;

        .notice {
          margin-top: 10px;
          font-weight: bold;
          white-space: pre-line;

          .brand {
            color: orange;
          }
        }
      }
    }

    .mask-tab {
      margin-top: -10px;

      /deep/ .el-tabs__header {
        padding-top: 10px;
        padding-left: 10px;
        margin-bottom: 0;
      }

      /deep/ .el-tabs__item {
        height: 34px;
        line-height: 34px;
      }
    }
  }

  .input-number {
    margin-left: 10px;
  }



  .concept-axis {
    width: 100%;
  }

  .upload-container {
    margin: 10px;
  }

  .target-container {
    margin-top: 10px;
    background-color: white;

    /deep/.el-card__header {
      padding: 5px 15px;
    }

    /deep/.el-card__body {
      padding: 5px;
    }

    .target-block {
      text-align: center;

      .target-img {
        max-width: 80px;
      }
    }

    .pagination-container {
      margin: 0;
      padding-top: 0;
      padding-bottom: 5px;
    }
  }

  /deep/ .el-upload--picture-card {
    width: 124px;
    height: 124px;
    line-height: 128px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 124px;
    height: 124px;
    line-height: 128px;
  }

  /deep/ .el-upload-list__item-status-label i {
    position: absolute;
    right: 15px
  }
}
</style>